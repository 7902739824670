<template>
<div id="guild-content">
<div id="loading" v-if="loading" class="valign-wrapper">
  <div>
    <div class="preloader-wrapper big active">
      <div class="spinner-layer spinner-blue-only">
        <div class="circle-clipper left">
          <div class="circle"></div>
        </div><div class="gap-patch">
        <div class="circle"></div>
      </div><div class="circle-clipper right">
        <div class="circle"></div>
      </div>
      </div>
    </div>
  </div>
</div>

<div v-else-if="loadingError" class="center">
  <h2>Error :(</h2>
  <button class="btn grey" @click="loadData()">Retry</button>
</div>

<div v-else-if="!tickets.length">
  <h2>No tickets</h2>
  <img src="https://cdn.join.chat/app/uploads/2020/05/tumbleweed.gif" alt="" class="responsive-img"/>
</div>

<div class="collection" v-else>
  <router-link :to="`./tickets/${ticket.id}`" v-for="ticket in tickets" :key="ticket.id" class="collection-item avatar black-text">
    <i class="material-icons circle">confirmation_number</i>
    <span class="title">#{{ ticket.id }} - {{ ticket.title }}</span>
    <p class="ticket-description">{{ ticket.description }}</p>
    <router-link v-if="$root.user.id===ticket.authorId" :to="`./tickets/${ticket.id}/edit`" class="secondary-content"><i class="material-icons black-text">edit</i></router-link>
  </router-link>
</div>

<div id="create-ticket-modal" class="modal">
  <div class="modal-content">
    <h4>New Ticket</h4>
    <p>create a new support ticket for this guild</p>
    <form>
      <div class="input-field">
        <input id="new-ticket-title" type="text" v-model="createTicketTitle">
        <label for="new-ticket-title">Title</label>
      </div>
      <div class="input-field">
        <textarea id="new-ticket-description" class="materialize-textarea" v-model="createTicketDescription"></textarea>
        <label for="new-ticket-description">Description</label>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <a class="modal-close waves-effect waves-grey btn-flat">Cancel</a>
    <a class="waves-effect waves-green btn" v-on:click="createTicket">create <i class="material-icons right">send</i></a>
  </div>
</div>
</div>
</template>

<script>
import { webserviceClient, webserviceDb } from "@/app/shared/services";
import { CreateTicketRequest, ListTicketsRequest } from "@grpc-interface/supportmanager";
import M from "materialize-css";

export default {
  name: "Guild",
  props: ["id"],
  data() {
    return {
      tickets: [],
      createTicketTitle: "",
      createTicketDescription: "",
      loading: true,
      loadingError: false,
    }
  },
  created() {
    this.loadData();
  },
  mounted() {
    M.AutoInit();
  },
  methods: {
    /**
     * Load support tickets using "stale while revalidate" strategy.
     */
    loadData() {
      this.loading = this.tickets.length === 0;  // only display loading state, when tickets are empty

      // load cache content
      webserviceDb.getGuildTickets(this.id)
        .then(async tickets => {
          if (this.tickets.length !== 0) {
            return  // tickets are already filled, no need for cache data
          }
          this.tickets = tickets;
          if (tickets.length) {
            this.loading = false;
          }
        });

      let request = new ListTicketsRequest();
      request.setGuildId(this.id);

      webserviceClient.listTickets(request)
        .then(response => {
          const tickets = response.getTicketsList();
          webserviceDb.insertTickets(tickets);
          this.tickets = tickets.map(ticket => ticket.toObject());
          this.loadingError = false;
        })
        .catch(async error => {
          if (!this.tickets.length) {
            // no tickets in cache
            if (window.navigator.onLine) {
              // network online, but failed
              console.error("Failed to fetch tickets", error);
              M.toast({html: "Failed to load tickets.", classes: "red"});
              this.loadingError = true;
            } else {
              // network offline
              M.toast({html: "Can't load tickets while offline.", displayLength: 8000});
              window.addEventListener("online", () => {
                M.toast({html: "You're back online.", classes: "green"});
                this.loadData();
              })
            }
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    createTicket() {
      const request = new CreateTicketRequest();
      request.setGuildId(this.id);
      request.setTitle(this.createTicketTitle);
      request.setDescription(this.createTicketDescription);

      webserviceClient.createTicket(request)
          .then(response => {
            alert(`Ticket created! #${response.getId()}`);
          })
    }
  },
}
</script>

<style scoped>
#guild-content {
  margin-top: 100px;
}
.ticket-description {
  padding-right: 2em;
}
#loading {
  width: 100%;
  margin-top: 40vh;
}
#loading > div {
  width: 50px;
  margin: 0 auto;
}
</style>
